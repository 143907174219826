import request from '../Axios'

// 列表
export const noiseQuery = data => {
  return request({
    url: '/noise/query',
    method: 'get',
    params: data
  })
}

// 立即核销 or 作废 or 备注
export const noiseUpdateRedeemStatus = data => {
  return request({
    url: '/noise/updateRedeemStatus',
    method: 'put',
    data
  })
}

// 导出记录
export const noiseExport = data => {
  return request({
    url: '/noise/export',
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}
