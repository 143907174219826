<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="download">导出记录</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button
              v-if="scoped.row.redeemStatus == 'not_redeemed'"
              type="primary"
              size="small"
              @click="writeOffItem(scoped.row)"
            >
              立即核销
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="voidItem(scoped.row)"
            >
              作废
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="remarkItem(scoped.row)"
            >
              备注
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="writeOffVisible"
      title="立即核销"
      width="50%"
      :before-close="writeOffClose"
    >
      <div class="writeoff_box">
        <span>奖券券码：</span>
        <el-input
          class="dialog_input"
          v-model="couponCode"
          placeholder="请输入"
        ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="writeOffSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="remarkVisible"
      title="备注"
      width="50%"
      :before-close="remarkClose"
    >
      <div class="writeoff_box remark_box">
        <span>备注：</span>
        <el-input
          v-model="remarkVal"
          :rows="5"
          type="textarea"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  noiseQuery,
  noiseUpdateRedeemStatus,
  noiseExport
} from '@/api/writeOffManagement'
import { constantQuery } from '@/api/user'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入关键字以搜索'
    },
    {
      title: '核销状态',
      label: 'writeOffStatus',
      type: 'options',
      options: [
        { value: 1, label: '未核销' },
        { value: 0, label: '已核销' }
      ],
      placeholder: '请选择'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    writeOffStatus: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'activityName', label: '活动名称' },
    { prop: 'userMobile', label: '用户手机号' },
    { prop: 'prizeName', label: '奖品名称' },
    { prop: 'lotteryCode', label: '奖品券码' },
    { prop: 'redeemStatusLabel', label: '核销状态' },
    { prop: 'remark', label: '备注' },
    { slot: 'action' }
  ],
  writeOffVisible: false,
  couponCode: '',
  writeOffItem: {},
  remarkVisible: false,
  remarkVal: '',
  remarkItem: {}
})

onMounted(() => {
  getRedeemStatus()
  getDataList()
})

// 核销状态
const getRedeemStatus = () => {
  constantQuery({ className: 'RedeemStatus' }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      formLabels.value[1].options = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    writeOffStatus: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 导出记录
const download = () => {
  if (fromData.value.keywords == '' && fromData.value.writeOffStatus == '') {
    ElMessageBox.confirm(
      '您当前未选择筛选条件，将导出所有记录。是否继续？',
      '提示：',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }
    )
      .then(() => {
        noiseExport().then(res => {
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.setAttribute('download', `活动奖品核销记录-${dateTime()}.xls`)
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(a.href)
          document.body.removeChild(a)
        })
      })
      .catch(() => {})
  } else {
    const data = {}
    if (fromData.value.keywords !== '') {
      data.keyword = fromData.value.keywords
    }
    if (fromData.value.writeOffStatus !== '') {
      data.redeemStatus = fromData.value.writeOffStatus
    }
    noiseExport(data).then(res => {
      let url = window.URL.createObjectURL(new Blob([res.data]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', `活动奖品核销记录-${dateTime()}.xls`)
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    })
  }
}

const dateTime = () => {
  const dateday = new Date()
  let year = dateday.getFullYear()
  let getMonth = dateday.getMonth() + 1
  let getDay = dateday.getDate()
  if (getMonth < 10) {
    getMonth
  }
  return `${year}-${getMonth < 10 ? '0' + getMonth : getMonth}-${
    getDay < 10 ? '0' + getDay : getDay
  }`
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  if (fromData.value.writeOffStatus !== '') {
    data.redeemStatus = fromData.value.writeOffStatus
  }
  noiseQuery(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 作废
const voidItem = row => {
  ElMessageBox.confirm('是否确认作废（该操作不可逆），是否继续？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      noiseUpdateRedeemStatus({
        id: row.id,
        type: 2
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 立即核销
const writeOffItem = row => {
  writeOffItem.value = row
  couponCode.value = ''
  writeOffVisible.value = true
}

// 立即核销确定
const writeOffSubmit = () => {
  if (couponCode.value == '') {
    ElMessage.warning('请输入奖券券码')
    return false
  }
  noiseUpdateRedeemStatus({
    id: writeOffItem.value.id,
    type: 1,
    lotteryCode: couponCode.value
  }).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      writeOffVisible.value = false
      getDataList()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 立即核销关闭
const writeOffClose = () => {
  writeOffItem.value = {}
  couponCode.value = ''
  writeOffVisible.value = false
}

// 备注
const remarkItem = row => {
  remarkVal.value = row.remark
  remarkItem.value = row
  remarkVisible.value = true
}

// 备注确定
const remarkSubmit = () => {
  if (remarkVal.value == '') {
    ElMessage.warning('请输入备注')
    return false
  }
  noiseUpdateRedeemStatus({
    id: remarkItem.value.id,
    type: 3,
    remark: remarkVal.value
  }).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      remarkVisible.value = false
      getDataList()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 立即核销关闭
const remarkClose = () => {
  remarkItem.value = {}
  remarkVal.value = ''
  remarkVisible.value = false
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  writeOffVisible,
  couponCode,
  remarkVisible,
  remarkVal
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog_input {
    width: 50%;
  }
}
.remark_box {
  align-items: flex-start;
}
</style>
